import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { HomeHero } from '../components/hero';
import styled from 'styled-components';
import breakpoints from '../components/utils/breakpoints';

const IndexPage = () => (
  <Layout>
    <Seo
      title="Shield Systems Brazilian Jiu Jitsu, mixed martial arts, kickboxing - Knoxville, Tennessee"
      description="Our kids and adult Jiu Jitsu, kickboxing and mixed martial arts programs will help you get in shape, learn discipline and have fun while doing it."
    />
    <HomeHero />
    <Wrapper>
      <p>
        Shield Systems Martial Arts and Fitness Academy is a unique,
        all-inclusive facility that blends the martial arts experience. We open
        our doors to all practitioners regardless of ability level with no
        judgements or biases. Our goal is to offer an environment where we can
        share knowledge and positively influence students’ lives and help them
        reach their individual goals.
      </p>
      <div id="leadcapture_31ecb54d-eee6-4220-8498-dfdfd3af3383"></div>
    </Wrapper>
  </Layout>
);

const Wrapper = styled.div`
  margin: 2.38rem auto 2.38rem auto;
  max-width: 960px;
  padding: 0 1.0875rem;

  @media (min-width: ${breakpoints.md}) {
    margin: 5rem auto 5rem auto;
  }
`;

export default IndexPage;
